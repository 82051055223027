import {
    List,
    Datagrid,
    TextField,
    DateField,
    Button,
    useRecordContext,
    BulkUpdateButton,
    BooleanInput,
    DeleteButton,
} from 'react-admin'
import browserHistory from 'browserHistory'
import { resourcesIds } from 'resources'
import { DropEvent, RaRecord } from 'types'

type CampaignListPanelLink = {
    resourceId?: (typeof resourcesIds)[keyof typeof resourcesIds]
    label: string
    noQuery?: boolean
    action?: (record: RaRecord<DropEvent>) => void
    disabled?: boolean | ((record: RaRecord<DropEvent>) => boolean)
}

type CampaignListProps = {
    postPanelLinks: CampaignListPanelLink[]
}

type CampaignPanelButton = CampaignListPanelLink & {
    record: RaRecord<DropEvent>
}

const CampaignPanelButton = ({
    label,
    resourceId,
    noQuery,
    action,
    record,
    disabled,
}: CampaignPanelButton) => {
    const { id } = record

    const isDisabled =
        typeof disabled === 'function' ? disabled(record) : disabled

    return (
        <Button
            disabled={isDisabled}
            onClick={() => {
                if (resourceId && id) {
                    browserHistory.push(
                        noQuery
                            ? { pathname: `${resourceId}/${id}` }
                            : {
                                  pathname: resourceId,
                                  search: `?value=${encodeURIComponent(id)}`,
                              }
                    )
                }
                if (action) {
                    action(record)
                }
            }}
            sx={{ padding: 1.5, margin: 1 }}
            label={label}
        />
    )
}

const CampaignPanel = ({ links }: { links: CampaignListPanelLink[] }) => {
    const record = useRecordContext<DropEvent>()
    return (
        <div style={{ display: 'flex', gap: 10 }}>
            {links.map(({ disabled, resourceId, label, noQuery, action }) => (
                <CampaignPanelButton
                    key={resourceId}
                    record={record}
                    resourceId={resourceId}
                    disabled={disabled}
                    label={label}
                    noQuery={noQuery}
                    action={action}
                />
            ))}
        </div>
    )
}

const CampaignsBulkActionButtons = () => (
    <BulkUpdateButton label="Update Deal ID" />
)

const CampaignsList = ({ postPanelLinks }: CampaignListProps) => (
    <List
        empty={false}
        sort={{ field: 'liveDate', order: 'ASC' }}
        filters={[
            <BooleanInput
                label="Show only live campaigns"
                source="isLive"
                key="isLive"
            />,
        ]}
    >
        <Datagrid
            expand={<CampaignPanel links={postPanelLinks} />}
            bulkActionButtons={<CampaignsBulkActionButtons />}
        >
            <TextField source="campaignName" />
            <TextField label="Brand name" source="brand.brandName" />
            <TextField source="id" />
            <DateField textAlign="center" label="Live date" source="liveDate" />
            <DateField
                textAlign="center"
                label="Expiration date"
                source="expirationDate"
            />
            <TextField label="Total points" source="pointsTotal" />
            <TextField
                label="Total organic points awarded"
                source="pointsAwarded"
            />
            <TextField label="Deal ID" source="dealId" />
            <DeleteButton mutationMode="pessimistic" />
        </Datagrid>
    </List>
)

export default CampaignsList
