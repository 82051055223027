import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin'
import {
    readableStates,
    verificationStates,
} from 'resourceDescriptions/verifications'
import { MultiVariantInput } from 'components/MultiVariantInput'
import { readablePlatformNames } from '../../constants/platforms'

export const CreateVerification = () => {
    return (
        <Create>
            <SimpleForm>
                <MultiVariantInput
                    variants={[
                        {
                            id: 'Username',
                            component: () => (
                                <TextInput source="mnemonicId" resettable />
                            ),
                        },
                        {
                            id: 'Platform Id',
                            component: () => (
                                <TextInput source="platformId" resettable />
                            ),
                        },
                    ]}
                />
                <SelectInput
                    source="platform"
                    choices={(['twitch', 'youtube', 'tiktok'] as const).map(
                        (platform) => ({
                            id: platform,
                            name: readablePlatformNames[platform],
                        })
                    )}
                />
                <SelectInput
                    source="state"
                    choices={verificationStates.map((state) => ({
                        id: state,
                        name: readableStates[state],
                    }))}
                />
            </SimpleForm>
        </Create>
    )
}
