import { z } from 'zod'

export const youTubeReportRequestBodySchema = z.object({
    games: z.array(z.string()).min(1),
    excludedGames: z.array(z.string()).optional(),
    categories: z.array(z.string()).min(1),
    topics: z.array(z.string()).optional(),
    countries: z.array(z.string()).optional(),
    subscribersMin: z.number().optional(),
    subscribersMax: z.number().optional(),
    // Last 30 days
    avgViewsMin: z.number().optional(),
    avgViewsMax: z.number().optional(),
    // Last 30 days
    channelViewsMin: z.number().optional(),
    channelViewsMax: z.number().optional(),
    dateFrom: z.string().optional(),
    dateTo: z.string().optional(),
    contacted: z.boolean().optional(),
    hasEmail: z.boolean().optional(),
    hasTikTok: z.boolean().optional(),
})

export type YouTubeReportRequestBody = z.infer<
    typeof youTubeReportRequestBodySchema
>
