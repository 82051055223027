import { Identifier } from 'react-admin'

export type FilterOption = { label: string; value: string }
export type TwitchGame = {
    box_art_url: string
    name: string
    id: string
}

export type TwitchUser = {
    id: string
    broadcaster_login: string
    display_name: string
}

export type CreatorApiCreator = {
    averageCcv: number
    contacted: boolean
    followers: number
    // gameId: string
    image: string
    language: string
    userId: string
    username: string
    email: string | null
    firstName: string | null
    discord: string | null
    instagram: string | null
    twitter: string | null
    youtube: string | null
}

export type DaredroAdminStackProps = {
    tags: {
        Stack: string
    }
    stackName: string
    domainName: string
    env: {
        region: string
        account: string
    }
}

export type AuthApiTokens = {
    accessToken: string
    refreshToken: string
}

export type AuthApiGroup =
    | 'viewer'
    | 'creator'
    | 'brand'
    | 'admin'
    | 'agency'
    | 'agencyAccountManager'
    | 'superAdmin'
    | 'betaTester'

export type AuthApiDecodedToken = {
    userId: string
    groups: AuthApiGroup[]
    email: string
    exp: number
}

export type ResourceParamType<T> =
    | T
    | number
    | Identifier
    | string
    | string[]
    | Record<string, any>
    | Record<string, any>[]

export type RaRecord<T> = T & { id: number | string }

export type StreamsStatsReportPayloadItem = {
    dateFrom: string
    dateTo: string
    creatorId: string
    gameId: string
}

export type GetMetricsFilters = {
    startAt: string
    endAt: string
}

export type Platform = 'twitch' | 'youtube' | 'tiktok' | 'facebook'

export enum DarePlatform {
    Twitch = 'twitch',
    YouTube = 'youtube',
    TikTok = 'tiktok',
    Any = 'any',
}

export enum ProductType {
    Key = 'dropKey',
    Code = 'dropCode',
    CustomProduct = 'customProduct',
    Placeholder = 'placeholder',
}

export type Product = { id: string; name: string; type: ProductType }

export enum DropEventStatus {
    ACTIVE = 'ACTIVE',
}

export enum DropEventSubscriptionStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export enum DropEventBudgetType {
    CAMPAIGN = 'CAMPAIGN',
}

export type DropEvent = {
    id: string
    brand: {
        id: string
    }
    initialProduct?: Product[]
    isPrivate: boolean
    rewardScalability?: {
        enabled: boolean
        rewards: {
            rewardId: string
            increments: {
                value: number
                platform: DarePlatform
            }[]
        }[]
    }
    dares: {
        product: Product
    }[]
    pointsLimit?: number
    approved: boolean
    status: DropEventStatus
    subscriptionStatus: DropEventSubscriptionStatus
    budget: { type: string }
    isUserActivated: boolean
    enableAutoApprove?: boolean
}

export type PointsAllowlistCreator = {
    platform: Platform
    platformId: string
    brandId: string
    dropId: string
    displayName: string
    created: string
}
