import { useRecordContext } from 'react-admin'
import { RaRecord } from 'types'
import { Verification } from 'resourceDescriptions/verifications'
import { Box } from '@mui/material'
import { Chip } from '@material-ui/core'

const useThreatsField = () => {
    const record = useRecordContext<RaRecord<Verification>>()

    const filteredThreats = Object.entries(record.context?.threats || {})
        .filter(([, value]) => value)
        .map(([name]) => name)

    return {
        value: filteredThreats,
    }
}

export const ThreatsField = () => {
    const { value } = useThreatsField()

    if (!value.length) {
        return <Box>No threats found</Box>
    }

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {value.map((threatName) => (
                <Chip key={threatName} label={threatName} />
            ))}
        </Box>
    )
}
