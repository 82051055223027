import { resourcesIds } from 'resources'
import {
    createKey,
    editListItemsIdKey,
    editOneItemIdKey,
    getListKey,
    getOneKey,
    updateKey,
} from 'keys'
import { Platform } from 'types'

export const verificationStates = [
    'verificationPending',
    'verificationVerified',
    'verificationRejected',
] as const

export type VerificationState = (typeof verificationStates)[number]

export type VerificationContextProfileThreats = Record<string, boolean>

export type VerificationContext = {
    email?: string
    accountCreatedAt?: string
    threats?: VerificationContextProfileThreats
}

export type Verification = {
    pk: string
    sk: `userVerification|${VerificationState}`
    mnemonicId: string
    context: VerificationContext | undefined
    created: string
    modified: string
}

export const readableStates: Record<VerificationState, string> = {
    verificationPending: 'Pending',
    verificationRejected: 'Rejected',
    verificationVerified: 'Verified',
}

type VerificationsListFilters = {
    state: VerificationState
    userId?: string
}

type CreateVerificationParams = {
    platform: Platform
    platformId: string
    state: VerificationState
    mnemonicId: string
}

type UpdateVerificationParams = {
    data: Verification & { state: VerificationState }
}

export const verifications = {
    [resourcesIds.VERIFICATIONS]: {
        [getListKey]: (params: { filter: VerificationsListFilters }) => {
            const state = params.filter.state || verificationStates[0]

            const userIds = params.filter.userId
                ? [params.filter.userId.trim()]
                : undefined

            return {
                endpointId: 'GET_VERIFICATIONS',
                payload: {
                    state,
                    userIds,
                },
            }
        },
        [editListItemsIdKey]: (items: Verification[]) => {
            return items.map((item) => {
                const [, state] = item.sk.split('|') as [
                    string,
                    VerificationState
                ]

                const [platform, platformId] = item.pk.split('|') as [
                    string,
                    string
                ]

                return {
                    ...item,
                    state,
                    platform,
                    platformId,
                    userId: item.mnemonicId,
                    id: item.pk,
                }
            })
        },
        [updateKey]: (params: UpdateVerificationParams) => {
            const [platform, platformId] = params.data.pk.split('|') as [
                string,
                string
            ]
            return {
                endpointId: 'SET_VERIFICATION_STATE',
                payload: {
                    platform,
                    platformId,
                    state: params.data.state,
                    userId: params.data.mnemonicId,
                },
            }
        },
        [editOneItemIdKey]: (item: Verification) => {
            const [, state] = item.sk.split('|') as [string, VerificationState]
            const [platform, platformId] = item.pk.split('|') as [
                string,
                string
            ]

            return {
                ...item,
                state,
                id: item.pk,
                platform,
                platformId,
                userId: item.mnemonicId,
            }
        },
        [createKey]: (params: CreateVerificationParams) => {
            return {
                endpointId: 'SET_VERIFICATION_STATE',
                payload: params,
            }
        },
        [getOneKey]: (key: string) => {
            const [platform, platformId] = key.split('|') as [string, string]
            return {
                endpointId: 'GET_VERIFICATION',
                payload: {
                    platform,
                    platformId,
                },
            }
        },
    },
}
